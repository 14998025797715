var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Thông tin khách hàng")]),(!_vm.customerId)?_c('CButton',{staticClass:"float-right",attrs:{"color":"info","size":"sm"},on:{"click":function($event){return _vm.$router.push({ name: 'List' })}}},[_c('CIcon',{attrs:{"name":"cil-list"}}),_vm._v(" Danh sách ")],1):_vm._e()],1),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"md":"2"}},[_c('CSelect',{attrs:{"label":"Danh xưng","placeholder":"Danh xưng","value":_vm.customer.Title,"add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['customer.Title'],
                },"invalid-feedback":_vm.inValidObject['customer.Title'],"options":Object.keys(_vm.$const.TITLES).map(function (_) {
                    return {
                      value: _,
                      label: _vm.$const.TITLES[_],
                    };
                  })},on:{"update:value":function($event){return _vm.$set(_vm.customer, "Title", $event)}}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Họ & Tên Khách hàng*")]),_c('v-select',{class:{
                    'is-invalid border-danger rounded': !!_vm.inValidObject[
                      'customer.Name'
                    ],
                  },attrs:{"options":_vm.customers,"filterable":false,"reduce":function (c) { return c.Id; },"taggable":true,"label":"Name","placeholder":"Họ & Tên Khách hàng*"},on:{"option:created":function (e) { return (_vm.customer.Name = e); },"search":function (search, loading) { return _vm.searchCustomers(search, loading); },"option:selected":function (e) { return _vm.chooseCustomer(e, 'Name'); }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"w-100"},[_c('h5',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(option.Name)+" "),(option.LastName)?_c('span',[_vm._v(" , "+_vm._s(option.LastName)+" ")]):_vm._e()]),_c('p',{staticClass:"mb-1"},[(option.Mobile)?_c('span',[_vm._v(" "+_vm._s(option.Mobile)+" - ")]):_vm._e(),_vm._v(" "+_vm._s(option.Address)+" ")])])]}}]),model:{value:(_vm.customer.Name),callback:function ($$v) {_vm.$set(_vm.customer, "Name", $$v)},expression:"customer.Name"}},[_c('template',{slot:"no-options"},[_vm._v(" Nhập Họ Tên KH để tìm kiếm ")])],2),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["customer.Name"])+" ")])],1)]),_c('CCol',{attrs:{"md":"2"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Số điện thoại")]),_c('v-select',{class:{
                    'is-invalid border-danger rounded': !!_vm.inValidObject[
                      'customer.Mobile'
                    ],
                  },attrs:{"options":_vm.customers,"filterable":false,"reduce":function (c) { return c.Id; },"taggable":true,"label":"Mobile","placeholder":"Số điện thoại"},on:{"option:created":function (e) { return (_vm.customer.Mobile = e); },"search":function (search, loading) { return _vm.searchCustomers(search, loading); },"option:selected":function (e) { return _vm.chooseCustomer(e, 'Mobile'); }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"w-100"},[_c('h5',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(option.Name)+" "),(option.LastName)?_c('span',[_vm._v(" , "+_vm._s(option.LastName)+" ")]):_vm._e()]),_c('p',{staticClass:"mb-1"},[(option.Mobile)?_c('span',[_vm._v(" "+_vm._s(option.Mobile)+" - ")]):_vm._e(),_vm._v(" "+_vm._s(option.Address)+" ")])])]}}]),model:{value:(_vm.customer.Mobile),callback:function ($$v) {_vm.$set(_vm.customer, "Mobile", $$v)},expression:"customer.Mobile"}},[_c('template',{slot:"no-options"},[_vm._v(" Nhập SĐT để tìm kiếm ")])],2),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["customer.Mobile"])+" ")])],1)]),_c('CCol',{attrs:{"md":"2"}},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',[_vm._v(" Ngày sinh ")]),_c('datetime',{class:{
                    'is-invalid': !!_vm.inValidObject['customer.Dob'],
                  },attrs:{"type":"date","format":"dd/MM/yyyy","input-class":{
                    'form-control': true,
                    'is-invalid': !!_vm.inValidObject['customer.Dob'],
                  },"value-zone":"local","default-value":"09-Oct-1989"},model:{value:(_vm.customer.Dob),callback:function ($$v) {_vm.$set(_vm.customer, "Dob", $$v)},expression:"customer.Dob"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["customer.Dob"])+" ")])],1)]),_c('CCol',{attrs:{"md":"3"}},[_c('CInput',{attrs:{"label":"Facebook","placeholder":"Facebook","add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['customer.FacebookLink'],
                },"invalid-feedback":_vm.inValidObject['customer.FacebookLink']},model:{value:(_vm.customer.FacebookLink),callback:function ($$v) {_vm.$set(_vm.customer, "FacebookLink", $$v)},expression:"customer.FacebookLink"}})],1),_c('CCol',{attrs:{"md":"2"}},[_c('CInput',{attrs:{"type":"email","label":"Địa chỉ email","placeholder":"customer@email.com","add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['customer.Email'],
                },"invalid-feedback":_vm.inValidObject['customer.Email']},model:{value:(_vm.customer.Email),callback:function ($$v) {_vm.$set(_vm.customer, "Email", $$v)},expression:"customer.Email"}})],1),_c('CCol',{attrs:{"md":"2"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Tỉnh/Thành")]),_c('v-select',{class:{
                    'is-invalid border-danger rounded': !!_vm.inValidObject[
                      'customer.ProvinceCode'
                    ],
                  },attrs:{"options":_vm.provinces,"reduce":function (p) { return p.Code; },"label":"Name","placeholder":"Tỉnh/Thành"},model:{value:(_vm.customer.ProvinceCode),callback:function ($$v) {_vm.$set(_vm.customer, "ProvinceCode", $$v)},expression:"customer.ProvinceCode"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["customer.ProvinceCode"])+" ")])],1)]),_c('CCol',{attrs:{"md":"2"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Quận/Huyện")]),_c('v-select',{class:{
                    'is-invalid border-danger rounded': !!_vm.inValidObject[
                      'customer.DistrictCode'
                    ],
                  },attrs:{"options":_vm.districts,"reduce":function (p) { return p.Code; },"label":"Name","placeholder":"Quận/Huyện"},model:{value:(_vm.customer.DistrictCode),callback:function ($$v) {_vm.$set(_vm.customer, "DistrictCode", $$v)},expression:"customer.DistrictCode"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["customer.DistrictCode"])+" ")])],1)]),_c('CCol',{attrs:{"md":"2"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Phường/Xã")]),_c('v-select',{class:{
                    'is-invalid border-danger rounded': !!_vm.inValidObject[
                      'customer.WardCode'
                    ],
                  },attrs:{"options":_vm.wards,"reduce":function (p) { return p.Code; },"label":"Name","placeholder":"Phường/Xã"},model:{value:(_vm.customer.WardCode),callback:function ($$v) {_vm.$set(_vm.customer, "WardCode", $$v)},expression:"customer.WardCode"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["customer.WardCode"])+" ")])],1)]),_c('CCol',{attrs:{"md":"4"}},[_c('CInput',{attrs:{"label":"Địa chỉ","placeholder":"Địa chỉ","add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['customer.Address'],
                },"invalid-feedback":_vm.inValidObject['customer.Address']},model:{value:(_vm.customer.Address),callback:function ($$v) {_vm.$set(_vm.customer, "Address", $$v)},expression:"customer.Address"}})],1)],1)],1),(_vm.customer.Id)?_c('CCardFooter',[_c('div',{staticClass:"form-row"},[_c('CCol',{staticClass:"col-form-label",attrs:{"tag":"label","col":"auto"}},[_vm._v(" Cập nhật thông tin khách hàng ")]),_c('CCol',{attrs:{"col":"auto"}},[_c('CSwitch',{staticClass:"mx-1",attrs:{"color":"info","size":"sm","variant":"opposite","shape":"pill","checked":_vm.isCustUpdated},on:{"update:checked":function($event){_vm.isCustUpdated=$event}}})],1)],1)]):_vm._e()],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Chi tiết "+_vm._s(_vm.pageName))])]),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"md":"3"}},[_c('CSelect',{attrs:{"label":"Kênh KH liên hệ","placeholder":"Chọn Kênh KH liên hệ","value":_vm.customerVisit.Source,"add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['customerVisit.Source'],
                },"invalid-feedback":_vm.inValidObject['customerVisit.Source'],"options":_vm.miscs
                    .filter(function (_) { return _.Type == 'Source'; })
                    .sort(function (a, b) { return (a.OrderNo >= b.OrderNo ? 1 : -1); })
                    .map(function (_) {
                      return { value: _.Value, label: _.Text };
                    })},on:{"update:value":function($event){return _vm.$set(_vm.customerVisit, "Source", $event)}}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('CSelect',{attrs:{"label":"Nội dung QC quan tâm","placeholder":"Chọn Nội dung QC quan tâm","value":_vm.customerVisit.Details.Interest,"add-input-classes":{
                  'is-invalid': !!_vm.inValidObject[
                    'customerVisit.Details.Interest'
                  ],
                },"invalid-feedback":_vm.inValidObject['customerVisit.Details.Interest'],"options":_vm.miscs
                    .filter(function (_) { return _.Type == 'Interest'; })
                    .sort(function (a, b) { return (a.OrderNo >= b.OrderNo ? 1 : -1); })
                    .map(function (_) {
                      return { value: _.Value, label: _.Text };
                    })},on:{"update:value":function($event){return _vm.$set(_vm.customerVisit.Details, "Interest", $event)}}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('CSelect',{attrs:{"label":"Nhận định","placeholder":"Chọn Nhận định","value":_vm.customerVisit.Details.Comments,"add-input-classes":{
                  'is-invalid': !!_vm.inValidObject[
                    'customerVisit.Details.Comments'
                  ],
                },"invalid-feedback":_vm.inValidObject['customerVisit.Details.Comments'],"options":_vm.miscs
                    .filter(function (_) { return _.Type == 'Comment'; })
                    .sort(function (a, b) { return (a.OrderNo >= b.OrderNo ? 1 : -1); })
                    .map(function (_) {
                      return { value: _.Value, label: _.Text };
                    })},on:{"update:value":function($event){return _vm.$set(_vm.customerVisit.Details, "Comments", $event)}}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('CSelect',{attrs:{"label":"Sản phẩm","placeholder":"Chọn Sản phẩm","value":_vm.customerVisit.ProductId,"add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['customerVisit.ProductId'],
                },"invalid-feedback":_vm.inValidObject['customerVisit.ProductId'],"options":_vm.products && _vm.products.length
                    ? _vm.products.map(function (_) {
                        return { value: _.Id, label: _.Name };
                      })
                    : []},on:{"update:value":function($event){return _vm.$set(_vm.customerVisit, "ProductId", $event)}}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('CSelect',{attrs:{"label":"Mức giá muốn mua","placeholder":"Chọn Mức giá muốn mua","value":_vm.customerVisit.Details.PriceRange,"add-input-classes":{
                  'is-invalid': !!_vm.inValidObject[
                    'customerVisit.Details.PriceRange'
                  ],
                },"invalid-feedback":_vm.inValidObject['customerVisit.Details.PriceRange'],"options":_vm.miscs
                    .filter(function (_) { return _.Type == 'PriceRange'; })
                    .sort(function (a, b) { return (a.OrderNo >= b.OrderNo ? 1 : -1); })
                    .map(function (_) {
                      return { value: _.Value, label: _.Text };
                    })},on:{"update:value":function($event){return _vm.$set(_vm.customerVisit.Details, "PriceRange", $event)}}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('CSelect',{attrs:{"label":("Kết quả " + _vm.pageName),"placeholder":("Chọn Kết quả " + _vm.pageName),"value":_vm.customerVisit.Details.NextStep,"add-input-classes":{
                  'is-invalid': !!_vm.inValidObject[
                    'customerVisit.Details.NextStep'
                  ],
                },"invalid-feedback":_vm.inValidObject['customerVisit.Details.NextStep'],"options":_vm.miscs
                    .filter(function (_) { return _.Type == 'NextStep'; })
                    .sort(function (a, b) { return (a.OrderNo >= b.OrderNo ? 1 : -1); })
                    .map(function (_) {
                      return { value: _.Value, label: _.Text };
                    })},on:{"update:value":function($event){return _vm.$set(_vm.customerVisit.Details, "NextStep", $event)}}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('CSelect',{attrs:{"label":"Trải nghiệm mua hàng","placeholder":"Chọn Trải nghiệm mua hàng","value":_vm.customerVisit.BuyingExp,"add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['customerVisit.BuyingExp'],
                },"invalid-feedback":_vm.inValidObject['customerVisit.BuyingExp'],"options":Object.keys(_vm.$const.BUYING_EXPERIENCES_TEXT).map(function (_) {
                    return {
                      value: _,
                      label: _vm.$const.BUYING_EXPERIENCES_TEXT[_],
                    };
                  })},on:{"update:value":function($event){return _vm.$set(_vm.customerVisit, "BuyingExp", $event)}}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('CInput',{attrs:{"label":"Ghi chú","placeholder":"Ghi chú","add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['customerVisit.Note'],
                },"invalid-feedback":_vm.inValidObject['customerVisit.Note']},model:{value:(_vm.customerVisit.Note),callback:function ($$v) {_vm.$set(_vm.customerVisit, "Note", $$v)},expression:"customerVisit.Note"}})],1),(!_vm.userAgencyId)?_c('CCol',{attrs:{"md":"3"}},[_c('CSelect',{attrs:{"label":"Đại lý","placeholder":"Chọn Đại lý","value":_vm.customerVisit.AgencyId,"add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['customerVisit.AgencyId'],
                },"invalid-feedback":_vm.inValidObject['customerVisit.AgencyId'],"options":_vm.agencies && _vm.agencies.length
                    ? _vm.agencies.map(function (_) {
                        return { value: _.Id, label: _.Name };
                      })
                    : []},on:{"update:value":function($event){return _vm.$set(_vm.customerVisit, "AgencyId", $event)}}})],1):_vm._e(),_c('CCol',{attrs:{"md":"3"}},[_c('CSelect',{attrs:{"label":"Chương trình Sale","placeholder":"Chọn Chương\n              trình Sale","value":_vm.customerVisit.Details.SaleProgram,"add-input-classes":{
                  'is-invalid': !!_vm.inValidObject[
                    'customerVisit.Details.SaleProgram'
                  ],
                },"invalid-feedback":_vm.inValidObject['customerVisit.Details.SaleProgram'],"options":[
                  { value: '', label: 'Không xác định' } ].concat( _vm.miscs
                    .filter(function (_) { return _.Type == 'SaleProgram'; })
                    .sort(function (a, b) { return (a.OrderNo >= b.OrderNo ? 1 : -1); })
                    .map(function (_) {
                      return { value: _.Value, label: _.Text };
                    }) )},on:{"update:value":function($event){return _vm.$set(_vm.customerVisit.Details, "SaleProgram", $event)}}})],1),_c('CCol',{attrs:{"md":"2"}},[_c('CSelect',{attrs:{"label":"KOL","placeholder":"Chọn KOL","value":_vm.customerVisit.Details.KOL,"add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['customerVisit.Details.KOL'],
                },"invalid-feedback":_vm.inValidObject['customerVisit.Details.KOL'],"options":[
                  { value: '', label: 'Không xác định' } ].concat( _vm.miscs
                    .filter(function (_) { return _.Type == 'KOL'; })
                    .sort(function (a, b) { return (a.OrderNo >= b.OrderNo ? 1 : -1); })
                    .map(function (_) {
                      return { value: _.Value, label: _.Text };
                    }) )},on:{"update:value":function($event){return _vm.$set(_vm.customerVisit.Details, "KOL", $event)}}})],1),_c('CCol',{attrs:{"md":"2"}},[_c('CInput',{attrs:{"label":"ID Nhóm quảng cáo","placeholder":"ID Nhóm quảng cáo","add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['customer.AdGroup'],
                },"invalid-feedback":_vm.inValidObject['customer.AdGroup']},model:{value:(_vm.customer.AdGroup),callback:function ($$v) {_vm.$set(_vm.customer, "AdGroup", $$v)},expression:"customer.AdGroup"}})],1),_c('CCol',{attrs:{"md":"2"}},[_c('CInput',{attrs:{"label":"ID Quảng cáo","placeholder":"ID Quảng cáo","add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['customer.Advertise'],
                },"invalid-feedback":_vm.inValidObject['customer.Advertise']},model:{value:(_vm.customer.Advertise),callback:function ($$v) {_vm.$set(_vm.customer, "Advertise", $$v)},expression:"customer.Advertise"}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('CSelect',{attrs:{"label":"Sự kiện quảng cáo","placeholder":"Chọn Sự kiện quảng cáo","value":_vm.customerVisit.Details.AdEvent,"add-input-classes":{
                  'is-invalid': !!_vm.inValidObject[
                    'customerVisit.Details.AdEvent'
                  ],
                },"invalid-feedback":_vm.inValidObject['customerVisit.Details.AdEvent'],"options":[
                  { value: '', label: 'Không xác định' } ].concat( _vm.miscs
                    .filter(function (_) { return _.Type == 'AdEvent'; })
                    .sort(function (a, b) { return (a.OrderNo >= b.OrderNo ? 1 : -1); })
                    .map(function (_) {
                      return { value: _.Value, label: _.Text };
                    }) )},on:{"update:value":function($event){return _vm.$set(_vm.customerVisit.Details, "AdEvent", $event)}}})],1),_c('CCol',{attrs:{"md":"1"}},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Quá khứ?")]),_c('div',{staticClass:"bg-gradient-light pl-2 pb-2 rounded"},[_c('CSwitch',{staticClass:"col-form-label",attrs:{"color":"info","size":"sm","shape":"pill","label":"","checked":_vm.customerVisit.IsPast},on:{"update:checked":function($event){return _vm.$set(_vm.customerVisit, "IsPast", $event)}}})],1)])]),(_vm.customerVisit.IsPast)?_c('CCol',{attrs:{"md":"2"}},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',[_vm._v(" Thời gian "+_vm._s(_vm.pageName)+" ")]),_c('div',[_c('datetime',{class:{
                      'is-invalid': !!_vm.inValidObject[
                        'customerVisit.CreatedAt'
                      ],
                    },attrs:{"type":"datetime","max-datetime":_vm.$moment()
                        .add(-1, 'day')
                        .endOf('day')
                        .toISOString(),"min-datetime":!_vm.isAdmin
                        ? _vm.$moment()
                            .add(-1, 'day')
                            .startOf('day')
                            .toISOString()
                        : null,"format":"dd/MM/yyyy HH:mm","input-class":{
                      'form-control': true,
                      'is-invalid': !!_vm.inValidObject[
                        'customerVisit.CreatedAt'
                      ],
                    }},model:{value:(_vm.customerVisit.CreatedAt),callback:function ($$v) {_vm.$set(_vm.customerVisit, "CreatedAt", $$v)},expression:"customerVisit.CreatedAt"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["customerVisit.CreatedAt"])+" ")])],1)])]):_vm._e()],1)],1),_c('CCardFooter',[(_vm.customerVisit.Details.NextStep == 'Bill')?_c('CButton',{attrs:{"color":"warning","disabled":_vm.saving},on:{"click":function($event){return _vm.saveCustomerVisit(true)}}},[_c('CIcon',{attrs:{"name":"cil-save"}}),_vm._v(" Lưu "+_vm._s(_vm.pageName)+" & tới trang tạo bill ")],1):_c('CButton',{attrs:{"color":"primary","disabled":_vm.saving},on:{"click":function($event){return _vm.saveCustomerVisit()}}},[_c('CIcon',{attrs:{"name":"cil-save"}}),_vm._v(" Lưu "+_vm._s(_vm.pageName)+" ")],1),(_vm.saving)?_c('div',{staticClass:"d-inline-block font-italic"},[_c('CSpinner',{staticClass:"ml-2",attrs:{"color":"info","size":"sm"}}),_vm._v(" Đang xử lý... ")],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
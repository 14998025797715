<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <span class="font-weight-bold">Thông tin khách hàng</span>
            <CButton
              v-if="!customerId"
              color="info"
              size="sm"
              class="float-right"
              @click="$router.push({ name: 'List' })"
            >
              <CIcon name="cil-list" />
              Danh sách
            </CButton>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol md="2">
                <CSelect
                  label="Danh xưng"
                  placeholder="Danh xưng"
                  :value.sync="customer.Title"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['customer.Title'],
                  }"
                  :invalid-feedback="inValidObject['customer.Title']"
                  :options="
                    Object.keys($const.TITLES).map((_) => {
                      return {
                        value: _,
                        label: $const.TITLES[_],
                      };
                    })
                  "
                />
              </CCol>
              <!-- <CCol md="1" class="col-6">
                <CInput
                  type="text"
                  label="Họ KH"
                  placeholder="Họ KH"
                  v-model="customer.LastName"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['customer.LastName'],
                  }"
                  :invalid-feedback="inValidObject['customer.LastName']"
                />
              </CCol> -->
              <CCol md="3">
                <div class="form-group">
                  <label>Họ & Tên Khách hàng*</label>
                  <v-select
                    v-model="customer.Name"
                    :options="customers"
                    :filterable="false"
                    :reduce="(c) => c.Id"
                    :taggable="true"
                    @option:created="(e) => (customer.Name = e)"
                    @search="
                      (search, loading) => searchCustomers(search, loading)
                    "
                    @option:selected="(e) => chooseCustomer(e, 'Name')"
                    label="Name"
                    placeholder="Họ & Tên Khách hàng*"
                    :class="{
                      'is-invalid border-danger rounded': !!inValidObject[
                        'customer.Name'
                      ],
                    }"
                  >
                    <template slot="no-options">
                      Nhập Họ Tên KH để tìm kiếm
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="w-100">
                        <h5 class="mb-1">
                          {{ option.Name }}
                          <span v-if="option.LastName">
                            , {{ option.LastName }}
                          </span>
                        </h5>
                        <p class="mb-1">
                          <span v-if="option.Mobile">
                            {{ option.Mobile }} -
                          </span>
                          {{ option.Address }}
                        </p>
                      </div>
                    </template>
                  </v-select>
                  <div class="invalid-feedback">
                    {{ inValidObject["customer.Name"] }}
                  </div>
                </div>
              </CCol>
              <CCol md="2">
                <div class="form-group">
                  <label>Số điện thoại</label>
                  <v-select
                    v-model="customer.Mobile"
                    :options="customers"
                    :filterable="false"
                    :reduce="(c) => c.Id"
                    :taggable="true"
                    @option:created="(e) => (customer.Mobile = e)"
                    @search="
                      (search, loading) => searchCustomers(search, loading)
                    "
                    @option:selected="(e) => chooseCustomer(e, 'Mobile')"
                    label="Mobile"
                    placeholder="Số điện thoại"
                    :class="{
                      'is-invalid border-danger rounded': !!inValidObject[
                        'customer.Mobile'
                      ],
                    }"
                  >
                    <template slot="no-options">
                      Nhập SĐT để tìm kiếm
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="w-100">
                        <h5 class="mb-1">
                          {{ option.Name }}
                          <span v-if="option.LastName">
                            , {{ option.LastName }}
                          </span>
                        </h5>
                        <p class="mb-1">
                          <span v-if="option.Mobile">
                            {{ option.Mobile }} -
                          </span>
                          {{ option.Address }}
                        </p>
                      </div>
                    </template>
                  </v-select>
                  <div class="invalid-feedback">
                    {{ inValidObject["customer.Mobile"] }}
                  </div>
                </div>
              </CCol>

              <CCol md="2">
                <div role="group" class="form-group">
                  <label>
                    Ngày sinh
                  </label>
                  <datetime
                    type="date"
                    v-model="customer.Dob"
                    format="dd/MM/yyyy"
                    :input-class="{
                      'form-control': true,
                      'is-invalid': !!inValidObject['customer.Dob'],
                    }"
                    :class="{
                      'is-invalid': !!inValidObject['customer.Dob'],
                    }"
                    value-zone="local"
                    default-value="09-Oct-1989"
                  />
                  <div class="invalid-feedback">
                    {{ inValidObject["customer.Dob"] }}
                  </div>
                </div>
              </CCol>

              <CCol md="3">
                <CInput
                  label="Facebook"
                  placeholder="Facebook"
                  v-model="customer.FacebookLink"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['customer.FacebookLink'],
                  }"
                  :invalid-feedback="inValidObject['customer.FacebookLink']"
                >
                </CInput>
              </CCol>

              <CCol md="2">
                <CInput
                  type="email"
                  label="Địa chỉ email"
                  placeholder="customer@email.com"
                  v-model="customer.Email"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['customer.Email'],
                  }"
                  :invalid-feedback="inValidObject['customer.Email']"
                />
              </CCol>

              <CCol md="2">
                <div class="form-group">
                  <label>Tỉnh/Thành</label>
                  <v-select
                    v-model="customer.ProvinceCode"
                    :options="provinces"
                    :reduce="(p) => p.Code"
                    label="Name"
                    placeholder="Tỉnh/Thành"
                    :class="{
                      'is-invalid border-danger rounded': !!inValidObject[
                        'customer.ProvinceCode'
                      ],
                    }"
                  />
                  <div class="invalid-feedback">
                    {{ inValidObject["customer.ProvinceCode"] }}
                  </div>
                </div>
              </CCol>
              <CCol md="2">
                <div class="form-group">
                  <label>Quận/Huyện</label>
                  <v-select
                    v-model="customer.DistrictCode"
                    :options="districts"
                    :reduce="(p) => p.Code"
                    label="Name"
                    placeholder="Quận/Huyện"
                    :class="{
                      'is-invalid border-danger rounded': !!inValidObject[
                        'customer.DistrictCode'
                      ],
                    }"
                  />
                  <div class="invalid-feedback">
                    {{ inValidObject["customer.DistrictCode"] }}
                  </div>
                </div>
              </CCol>
              <CCol md="2">
                <div class="form-group">
                  <label>Phường/Xã</label>
                  <v-select
                    v-model="customer.WardCode"
                    :options="wards"
                    :reduce="(p) => p.Code"
                    label="Name"
                    placeholder="Phường/Xã"
                    :class="{
                      'is-invalid border-danger rounded': !!inValidObject[
                        'customer.WardCode'
                      ],
                    }"
                  />
                  <div class="invalid-feedback">
                    {{ inValidObject["customer.WardCode"] }}
                  </div>
                </div>
              </CCol>
              <CCol md="4">
                <CInput
                  label="Địa chỉ"
                  placeholder="Địa chỉ"
                  v-model="customer.Address"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['customer.Address'],
                  }"
                  :invalid-feedback="inValidObject['customer.Address']"
                />
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter v-if="customer.Id">
            <div class="form-row">
              <CCol tag="label" col="auto" class="col-form-label">
                Cập nhật thông tin khách hàng
              </CCol>
              <CCol col="auto">
                <CSwitch
                  class="mx-1"
                  color="info"
                  size="sm"
                  variant="opposite"
                  shape="pill"
                  :checked.sync="isCustUpdated"
                />
              </CCol>
              <!-- <CCol tag="label" col="auto" class="col-form-label">
                Chăm sóc khách hàng
              </CCol>
              <CCol col="auto">
                <CSwitch
                  class="mx-1"
                  color="info"
                  size="sm"
                  variant="opposite"
                  shape="pill"
                  :checked.sync="isCare"
                />
              </CCol> -->
            </div>
          </CCardFooter>
        </CCard>
      </CCol>

      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <span class="font-weight-bold">Chi tiết {{ pageName }}</span>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol md="3">
                <CSelect
                  label="Kênh KH liên hệ"
                  placeholder="Chọn Kênh KH liên hệ"
                  :value.sync="customerVisit.Source"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['customerVisit.Source'],
                  }"
                  :invalid-feedback="inValidObject['customerVisit.Source']"
                  :options="
                    miscs
                      .filter((_) => _.Type == 'Source')
                      .sort((a, b) => (a.OrderNo >= b.OrderNo ? 1 : -1))
                      .map((_) => {
                        return { value: _.Value, label: _.Text };
                      })
                  "
                />
              </CCol>
              <CCol md="3">
                <CSelect
                  label="Nội dung QC quan tâm"
                  placeholder="Chọn Nội dung QC quan tâm"
                  :value.sync="customerVisit.Details.Interest"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject[
                      'customerVisit.Details.Interest'
                    ],
                  }"
                  :invalid-feedback="
                    inValidObject['customerVisit.Details.Interest']
                  "
                  :options="
                    miscs
                      .filter((_) => _.Type == 'Interest')
                      .sort((a, b) => (a.OrderNo >= b.OrderNo ? 1 : -1))
                      .map((_) => {
                        return { value: _.Value, label: _.Text };
                      })
                  "
                />
              </CCol>
              <CCol md="3">
                <CSelect
                  label="Nhận định"
                  placeholder="Chọn Nhận định"
                  :value.sync="customerVisit.Details.Comments"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject[
                      'customerVisit.Details.Comments'
                    ],
                  }"
                  :invalid-feedback="
                    inValidObject['customerVisit.Details.Comments']
                  "
                  :options="
                    miscs
                      .filter((_) => _.Type == 'Comment')
                      .sort((a, b) => (a.OrderNo >= b.OrderNo ? 1 : -1))
                      .map((_) => {
                        return { value: _.Value, label: _.Text };
                      })
                  "
                />
              </CCol>
              <CCol md="3">
                <CSelect
                  label="Sản phẩm"
                  placeholder="Chọn Sản phẩm"
                  :value.sync="customerVisit.ProductId"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['customerVisit.ProductId'],
                  }"
                  :invalid-feedback="inValidObject['customerVisit.ProductId']"
                  :options="
                    products && products.length
                      ? products.map((_) => {
                          return { value: _.Id, label: _.Name };
                        })
                      : []
                  "
                />
              </CCol>
              <CCol md="3">
                <CSelect
                  label="Mức giá muốn mua"
                  placeholder="Chọn Mức giá muốn mua"
                  :value.sync="customerVisit.Details.PriceRange"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject[
                      'customerVisit.Details.PriceRange'
                    ],
                  }"
                  :invalid-feedback="
                    inValidObject['customerVisit.Details.PriceRange']
                  "
                  :options="
                    miscs
                      .filter((_) => _.Type == 'PriceRange')
                      .sort((a, b) => (a.OrderNo >= b.OrderNo ? 1 : -1))
                      .map((_) => {
                        return { value: _.Value, label: _.Text };
                      })
                  "
                />
              </CCol>
              <CCol md="3">
                <CSelect
                  :label="`Kết quả ${pageName}`"
                  :placeholder="`Chọn Kết quả ${pageName}`"
                  :value.sync="customerVisit.Details.NextStep"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject[
                      'customerVisit.Details.NextStep'
                    ],
                  }"
                  :invalid-feedback="
                    inValidObject['customerVisit.Details.NextStep']
                  "
                  :options="
                    miscs
                      .filter((_) => _.Type == 'NextStep')
                      .sort((a, b) => (a.OrderNo >= b.OrderNo ? 1 : -1))
                      .map((_) => {
                        return { value: _.Value, label: _.Text };
                      })
                  "
                />
              </CCol>
              <CCol md="3">
                <CSelect
                  :label="`Trải nghiệm mua hàng`"
                  :placeholder="`Chọn Trải nghiệm mua hàng`"
                  :value.sync="customerVisit.BuyingExp"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['customerVisit.BuyingExp'],
                  }"
                  :invalid-feedback="inValidObject['customerVisit.BuyingExp']"
                  :options="
                    Object.keys($const.BUYING_EXPERIENCES_TEXT).map((_) => {
                      return {
                        value: _,
                        label: $const.BUYING_EXPERIENCES_TEXT[_],
                      };
                    })
                  "
                />
              </CCol>
              <CCol md="3">
                <CInput
                  label="Ghi chú"
                  placeholder="Ghi chú"
                  v-model="customerVisit.Note"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['customerVisit.Note'],
                  }"
                  :invalid-feedback="inValidObject['customerVisit.Note']"
                />
              </CCol>

              <CCol md="3" v-if="!userAgencyId">
                <CSelect
                  label="Đại lý"
                  placeholder="Chọn Đại lý"
                  :value.sync="customerVisit.AgencyId"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['customerVisit.AgencyId'],
                  }"
                  :invalid-feedback="inValidObject['customerVisit.AgencyId']"
                  :options="
                    agencies && agencies.length
                      ? agencies.map((_) => {
                          return { value: _.Id, label: _.Name };
                        })
                      : []
                  "
                />
              </CCol>

              <CCol md="3">
                <CSelect
                  label="Chương trình Sale"
                  placeholder="Chọn Chương
                trình Sale"
                  :value.sync="customerVisit.Details.SaleProgram"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject[
                      'customerVisit.Details.SaleProgram'
                    ],
                  }"
                  :invalid-feedback="
                    inValidObject['customerVisit.Details.SaleProgram']
                  "
                  :options="[
                    { value: '', label: 'Không xác định' },
                    ...miscs
                      .filter((_) => _.Type == 'SaleProgram')
                      .sort((a, b) => (a.OrderNo >= b.OrderNo ? 1 : -1))
                      .map((_) => {
                        return { value: _.Value, label: _.Text };
                      }),
                  ]"
                />
              </CCol>

              <CCol md="2">
                <CSelect
                  label="KOL"
                  placeholder="Chọn KOL"
                  :value.sync="customerVisit.Details.KOL"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['customerVisit.Details.KOL'],
                  }"
                  :invalid-feedback="inValidObject['customerVisit.Details.KOL']"
                  :options="[
                    { value: '', label: 'Không xác định' },
                    ...miscs
                      .filter((_) => _.Type == 'KOL')
                      .sort((a, b) => (a.OrderNo >= b.OrderNo ? 1 : -1))
                      .map((_) => {
                        return { value: _.Value, label: _.Text };
                      }),
                  ]"
                />
              </CCol>

              <CCol md="2">
                <CInput
                  label="ID Nhóm quảng cáo"
                  placeholder="ID Nhóm quảng cáo"
                  v-model="customer.AdGroup"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['customer.AdGroup'],
                  }"
                  :invalid-feedback="inValidObject['customer.AdGroup']"
                />
              </CCol>

              <CCol md="2">
                <CInput
                  label="ID Quảng cáo"
                  placeholder="ID Quảng cáo"
                  v-model="customer.Advertise"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['customer.Advertise'],
                  }"
                  :invalid-feedback="inValidObject['customer.Advertise']"
                />
              </CCol>

              <CCol md="3">
                <CSelect
                  label="Sự kiện quảng cáo"
                  placeholder="Chọn Sự kiện quảng cáo"
                  :value.sync="customerVisit.Details.AdEvent"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject[
                      'customerVisit.Details.AdEvent'
                    ],
                  }"
                  :invalid-feedback="
                    inValidObject['customerVisit.Details.AdEvent']
                  "
                  :options="[
                    { value: '', label: 'Không xác định' },
                    ...miscs
                      .filter((_) => _.Type == 'AdEvent')
                      .sort((a, b) => (a.OrderNo >= b.OrderNo ? 1 : -1))
                      .map((_) => {
                        return { value: _.Value, label: _.Text };
                      }),
                  ]"
                />
              </CCol>

              <CCol md="1">
                <div role="group" class="form-group">
                  <label class="font-weight-bold">Quá khứ?</label>
                  <div class="bg-gradient-light pl-2 pb-2 rounded">
                    <CSwitch
                      class="col-form-label"
                      color="info"
                      size="sm"
                      shape="pill"
                      label
                      :checked.sync="customerVisit.IsPast"
                    />
                  </div>
                </div>
              </CCol>
              <CCol md="2" v-if="customerVisit.IsPast">
                <div role="group" class="form-group">
                  <label> Thời gian {{ pageName }} </label>
                  <div>
                    <datetime
                      type="datetime"
                      v-model="customerVisit.CreatedAt"
                      :max-datetime="
                        $moment()
                          .add(-1, 'day')
                          .endOf('day')
                          .toISOString()
                      "
                      :min-datetime="
                        !isAdmin
                          ? $moment()
                              .add(-1, 'day')
                              .startOf('day')
                              .toISOString()
                          : null
                      "
                      format="dd/MM/yyyy HH:mm"
                      :input-class="{
                        'form-control': true,
                        'is-invalid': !!inValidObject[
                          'customerVisit.CreatedAt'
                        ],
                      }"
                      :class="{
                        'is-invalid': !!inValidObject[
                          'customerVisit.CreatedAt'
                        ],
                      }"
                    />
                    <div class="invalid-feedback">
                      {{ inValidObject["customerVisit.CreatedAt"] }}
                    </div>
                  </div>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton
              v-if="customerVisit.Details.NextStep == 'Bill'"
              color="warning"
              @click="saveCustomerVisit(true)"
              :disabled="saving"
            >
              <CIcon name="cil-save" />
              Lưu {{ pageName }} & tới trang tạo bill
            </CButton>
            <CButton
              v-else
              color="primary"
              @click="saveCustomerVisit()"
              :disabled="saving"
            >
              <CIcon name="cil-save" />
              Lưu {{ pageName }}
            </CButton>
            <div class="d-inline-block font-italic" v-if="saving">
              <CSpinner color="info" size="sm" class="ml-2" />
              Đang xử lý...
            </div>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

export default {
  name: "Visit",
  components: { Datetime },
  props: ["isCare", "customerId"],
  data() {
    return {
      isCustUpdated: false,
      customer: {
        Id: 0,
        Title: Object.keys(this.$const.TITLES)[0],
        Name: null,
        LastName: null,
        Mobile: null,
        Email: null,
        FacebookLink: null,
        Address: null,
        Dob: null,
        ProvinceCode: null,
        DistrictCode: null,
        WardCode: null,
      },
      customerVisit: {
        ProductId: null,
        Source: null,
        Details: {
          Interest: null,
          Comments: null,
          PriceRange: null,
          NextStep: null,
          SaleProgram: "",
          KOL: "",
          AdEvent: "",
        },
        Note: null,
        AgencyId: null,
        BuyingExp: null,
        IsPast: false,
        CreatedAt: null,
        AdGroup: null,
        Advertise: null,
      },
      customers: [],
      agencies: null,
      products: null,
      provinces: [],
      districts: [],
      wards: [],
      miscs: [],
      inValidObject: {},
      saving: false,
    };
  },
  async mounted() {
    this.products = await this.loadProducts();
    if (!this.userAgencyId) {
      this.agencies = await this.loadAgencies();
    }
    this.provinces = await this.loadProvinces();
    this.miscs = await this.loadMiscs();

    await this.loadCustomer(this.customerId);
  },
  computed: {
    userAgencyId() {
      let authUser = this.$user.getters.authUser;
      return authUser &&
        authUser.role == this.$const.ROLES.Owner &&
        authUser.agencyIds &&
        authUser.agencyIds != "all" &&
        !authUser.agencyIds.includes(",")
        ? parseInt(authUser.agencyIds)
        : null;
    },

    isAdmin() {
      let authUser = this.$user.getters.authUser;
      return authUser
        ? authUser && authUser.role == this.$const.ROLES.SuperAdmin
        : false;
    },

    pageName() {
      let isCare = this.isCare;
      return isCare ? "CSKH" : "Ghé thăm";
    },
  },
  watch: {
    async "customer.ProvinceCode"(val) {
      this.districts = await this.loadDistricts(val);
    },

    async "customer.DistrictCode"(val) {
      this.wards = await this.loadWards(val);
    },

    "customerVisit.Source"(val) {
      if (val == "Walk") {
        this.customerVisit.BuyingExp = this.$const.BUYING_EXPERIENCES.Counter;
      }
    },

    "customer.Mobile"(val) {
      if (val && val.length == 11) {
        alert("Chú ý: Bạn vừa nhập số điện thoại có 11 số!");
      }
    },

    "customer.Id"(val) {
      if (!val) {
        this.isCustUpdated = false;
      }
    },
  },
  methods: {
    async loadCustomer(customerId) {
      if (customerId) {
        let filters = [];
        filters.push(`Status eq ${this.$const.STATUS.Active}`);
        filters.push(`Id eq ${customerId}`);
        let customers = await this.loadCustomers(filters.join(" and "));
        if (customers && customers.length) {
          let customer = customers[0];
          this.customer = {
            Id: customer.Id,
            Title: customer.Title,
            Name: customer.Name,
            LastName: customer.LastName,
            Mobile: customer.Mobile,
            Email: customer.Email,
            FacebookLink: customer.FacebookLink,
            Address: customer.Address,
            Dob: customer.Dob,
            ProvinceCode: customer.ProvinceCode,
            DistrictCode: customer.DistrictCode,
            WardCode: customer.WardCode,
          };
          this.isCustUpdated = this.isCare ? true : false;
        }
      }
    },

    async searchCustomers(search, loading) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        let results = [];
        if (!search) {
          return;
        }

        loading(true);
        try {
          let filters = [];
          filters.push(`Status eq ${this.$const.STATUS.Active}`);

          if (isNaN(search) && search.includes(",")) {
            if (search.includes(",")) {
              let fName = search.split(",")[0].trim();
              fName && filters.push(`contains(Name, '${fName}')`);

              let lName = search.split(",")[1].trim();
              lName && filters.push(`contains(LastName, '${lName}')`);
            } else {
              filters.push(`contains(Name, '${search}')`);
            }
          }
          !isNaN(search) && filters.push(`contains(Mobile, '${search}')`);

          results = await this.loadCustomers(filters.join(" and "));
        } catch (error) {
          alert(error);
        }
        loading(false);

        this.customers = results;
      }, 1000);
    },

    chooseCustomer(customer, field) {
      if (typeof customer === "string") {
        this.customer[field] = customer;
      } else {
        if (Object.keys(customer).length > 1) {
          this.customer = {
            Id: customer.Id,
            Title: customer.Title,
            Name: customer.Name,
            LastName: customer.LastName,
            Mobile: customer.Mobile,
            Email: customer.Email,
            FacebookLink: customer.FacebookLink,
            Address: customer.Address,
            Dob: customer.Dob,
            ProvinceCode: customer.ProvinceCode,
            DistrictCode: customer.DistrictCode,
            WardCode: customer.WardCode,
          };
        } else {
          this.customer[field] = customer[field];
        }
      }
    },

    async loadCustomers(filter) {
      let results = [];
      let resp = await this.$http.get(`odata/Customer`, {
        params: {
          $top: 10,
          $filter: filter,
          $select:
            "Id,Title,Name,LastName,Mobile,Email,FacebookLink,Address,Dob,ProvinceCode,DistrictCode,WardCode",
          isAll: true,
        },
      });
      if (resp && resp.status == 200) {
        results = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Title: _.Title,
            Name: _.Name,
            LastName: _.LastName,
            Mobile: _.Mobile,
            Email: _.Email,
            FacebookLink: _.FacebookLink,
            Address: _.Address,
            Dob: _.Dob,
            ProvinceCode: _.ProvinceCode,
            DistrictCode: _.DistrictCode,
            WardCode: _.WardCode,
          };
        });
      }
      return results;
    },

    async loadAgencies() {
      let agencies = [];
      let active = this.$const.STATUS.Active;
      let resp = await this.$http.get("odata/Agency", {
        params: {
          $top: 1000,
          $filter: `Status eq ${active}`,
          $select: "Id,Name",
        },
      });
      if (resp && resp.status == 200) {
        agencies = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Name: _.Name,
          };
        });
      }
      return agencies;
    },

    async loadProducts() {
      let products = [];
      let active = this.$const.STATUS.Active;
      let newBrand = this.$const.PRODUCT_TYPES.NewBrand;
      let liquidation = this.$const.PRODUCT_TYPES.Liquidation;
      let likeNew = this.$const.PRODUCT_TYPES.LikeNew;
      let resp = await this.$http.get("odata/Product", {
        params: {
          $top: 1000,
          $filter: `Status eq ${active} and ProductType in (${[
            newBrand,
            liquidation,
            likeNew,
          ]})`,
          $select: "Id,Name",
        },
      });
      if (resp && resp.status == 200) {
        products = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Name: _.Name,
          };
        });
      }
      return products;
    },

    async loadProvinces() {
      let result = [];
      let resp = await this.$http.get("odata/Province", {
        params: { $top: 100, $select: "Code,Name" },
      });
      if (resp && resp.status == 200) {
        result = resp.data.value.map((_) => {
          return {
            Code: _.Code,
            Name: _.Name,
          };
        });
      }
      return result;
    },

    async loadDistricts(provinceCode) {
      let result = [];
      if (provinceCode) {
        let resp = await this.$http.get("odata/District", {
          params: {
            $top: 100,
            $select: "Code,Name",
            $filter: `ProvinceCode eq '${provinceCode}'`,
          },
        });
        if (resp && resp.status == 200) {
          result = resp.data.value.map((_) => {
            return {
              Code: _.Code,
              Name: _.Name,
            };
          });
        }
      }
      return result;
    },

    async loadWards(districtCode) {
      let result = [];
      if (districtCode) {
        let resp = await this.$http.get("odata/Ward", {
          params: {
            $top: 100,
            $select: "Code,Name",
            $filter: `DistrictCode eq '${districtCode}'`,
          },
        });
        if (resp && resp.status == 200) {
          result = resp.data.value.map((_) => {
            return {
              Code: _.Code,
              Name: _.Name,
            };
          });
        }
      }
      return result;
    },

    async loadMiscs() {
      let types = [
        "Source",
        "Interest",
        "Comment",
        "PriceRange",
        "NextStep",
        "SaleProgram",
        "KOL",
        "AdEvent",
      ];
      let result = [];
      let resp = await this.$http.get("odata/Misc", {
        params: {
          $top: 200,
          $select: "Value,Text,OrderNo,Type",
          $filter: `Status eq ${
            this.$const.STATUS.Active
          } and Type in ('${types.join("','")}')`,
        },
      });
      if (resp && resp.status == 200) {
        result = resp.data.value.map((_) => {
          return {
            Value: _.Value,
            Text: _.Text,
            OrderNo: _.OrderNo,
            Type: _.Type,
          };
        });
      }
      return result;
    },

    async saveCustomerVisit(createBill) {
      let customer = this.customer;
      let customerVisit = this.customerVisit;
      let userAgencyId = this.userAgencyId;
      let isCustUpdated = this.isCustUpdated;
      let isCare = this.isCare;

      this.inValidObject = this.checkValid(
        customer,
        customerVisit,
        userAgencyId
      );

      if (Object.keys(this.inValidObject).length) {
        alert("Thông tin nhập chưa đúng, vui lòng kiểm tra lại!");
        return;
      }

      this.saving = true;
      try {
        let custData = {
          Title: customer.Title,
          Name: customer.Name,
          LastName: customer.LastName,
          Mobile: customer.Mobile,
          Email: customer.Email,
          FacebookLink: customer.FacebookLink,
          Address: customer.Address,
          Dob: this.$moment(customer.Dob).toISOString(),
          ProvinceCode: customer.ProvinceCode,
          DistrictCode: customer.DistrictCode,
          WardCode: customer.WardCode,
        };
        // Customer
        if (!customer.Id) {
          // post
          custData.Status = this.$const.STATUS.Active;

          let respPost = await this.$http.post(`odata/Customer`, custData);
          if (respPost && respPost.status == 201) {
            customer.Id = respPost.data.Id;
          }
        } else if (isCustUpdated) {
          // patch
          await this.$http.patch(`odata/Customer/${customer.Id}`, custData);
        }

        // Customer Visit
        if (customer.Id) {
          let visitData = {
            CustomerId: customer.Id,
            ProductId: customerVisit.ProductId,
            Details: JSON.stringify(customerVisit.Details),
            Source: customerVisit.Source,
            Status: this.$const.STATUS.Active,
            Note: customerVisit.Note,
            AgencyId: userAgencyId ? userAgencyId : customerVisit.AgencyId,
            BuyingExp: customerVisit.BuyingExp,
            IsPast: customerVisit.IsPast,
            AdGroup: customerVisit.AdGroup,
            Advertise: customerVisit.Advertise,
            IsCare: isCare,
          };
          if (customerVisit.IsPast) {
            visitData.CreatedAt = customerVisit.CreatedAt;
          }
          let resp = await this.$http.post(`odata/CustomerVisit`, visitData);
          if (resp && resp.status == 201) {
            let visitId = resp.data.Id;
            this.clearForm();
            if (createBill) {
              alert(
                `Tạo ${this.pageName} thành công! Bấm OK để tiếp tục tạo Bill`
              );
              this.$router.push({ name: "OrderCreate", params: { visitId } });
            } else {
              alert(`Tạo ${this.pageName} thành công!`);
              if (this.customerId) {
                //this.$router.replace({ name: "Visit" });
                this.$emit("close");
              }
            }
          }
        } else {
          alert("Thông tin khách hàng sai!");
        }
      } catch (error) {
        alert(error);
      }

      this.saving = false;
    },

    checkValid(customer, customerVisit, userAgencyId) {
      let inValidObject = {};
      if (!customer.Title)
        inValidObject["customer.Title"] = "Vui lòng chọn Danh xưng!";
      if (!customer.Name)
        inValidObject["customer.Name"] = "Vui lòng nhập Tên khách hàng!";
      if (customer.Mobile) {
        //let mReg = /(0[3|5|7|8|9])+([0-9]{8})\b/g;
        let mReg = /([0]{1}[0-9]{9,10})\b/g;
        let mRes = mReg.test(customer.Mobile);
        if (!mRes) {
          inValidObject["customer.Mobile"] = "Số điện thoại chưa đúng!";
        }
      }
      // if (!customer.Address)
      //   inValidObject["customer.Address"] = "Vui lòng nhập Địa chỉ!";

      // if (!customer.ProvinceCode)
      //   inValidObject["customer.ProvinceCode"] = "Vui lòng nhập Tỉnh/thành!";
      // if (!customer.DistrictCode)
      //   inValidObject["customer.DistrictCode"] = "Vui lòng nhập Quận/huyện!";

      if (!customerVisit.Source) {
        inValidObject["customerVisit.Source"] =
          "Vui lòng chọn Kênh KH liên hệ!";
      }
      if (!customerVisit.Details.Interest) {
        inValidObject["customerVisit.Details.Interest"] =
          "Vui lòng chọn Nội dung QC quan tâm!";
      }
      if (!customerVisit.Details.Comments) {
        inValidObject["customerVisit.Details.Comments"] =
          "Vui lòng chọn Nhận định!";
      }
      if (customerVisit.Details.NextStep) {
        if (customerVisit.Details.NextStep == "Bill" && !customer.Dob) {
          inValidObject["customer.Dob"] = "Chốt Bill yêu cầu ngày sinh KH!";
        }
      } else {
        inValidObject[
          "customerVisit.Details.NextStep"
        ] = `Vui lòng chọn Kết quả ${this.pageName}!`;
      }
      if (!customerVisit.BuyingExp) {
        inValidObject["customerVisit.BuyingExp"] =
          "Vui lòng chọn Trải nghiệm mua hàng!";
      }
      if (!userAgencyId && !customerVisit.AgencyId) {
        inValidObject["customerVisit.AgencyId"] = "Vui lòng chọn Đại lý!";
      }
      if (customerVisit.IsPast && !customerVisit.CreatedAt) {
        inValidObject[
          "customerVisit.CreatedAt"
        ] = `Vui lòng chọn Ngày ${this.pageName}!`;
      }

      return inValidObject;
    },

    clearForm() {
      this.customer = {
        Id: 0,
        Title: Object.keys(this.$const.TITLES)[0],
        Name: null,
        LastName: null,
        Mobile: null,
        Email: null,
        FacebookLink: null,
        Address: null,
        Dob: null,
        ProvinceCode: null,
        DistrictCode: null,
        WardCode: null,
      };
      this.customerVisit = {
        ProductId: null,
        Source: null,
        Details: {
          Interest: null,
          Comments: null,
          PriceRange: null,
          NextStep: null,
          SaleProgram: "",
          KOL: "",
          AdEvent: "",
        },
        Note: null,
        BuyingExp: this.$const.BUYING_EXPERIENCES.Counter,
        IsPast: false,
        CreatedAt: null,
        AdGroup: null,
        Advertise: null,
      };
      this.isCustUpdated = false;
    },
  },
};
</script>
